import React from 'react'
import mediaData from '../../media.json'
import './TimelineComponent.css'
import moment from 'moment'
import { Chart } from 'react-google-charts' // https://www.npmjs.com/package/react-google-charts | This is the library that we will use to create the timeline

export default function TimelineComponent () {
  // Helper Functions -
  const ticksToDate = (ticks: number) => {
    return Number.isInteger(ticks)
      ? new Date(ticks / 1e4 + new Date('0001-01-01T00:00:00Z').getTime())
      : null
  }

  // formatTime obj using moment.js
  const formatTime = (obj: any) => {
    const formatDate = moment.utc(obj).format('YYYY-MM-DD HH:mm:ss')
    // console.log(formatDate, 'formatDate')
    const year = moment(formatDate).year()
    const month = moment(formatDate).month()
    const day = moment(formatDate).date()
    const hour = moment(formatDate).hour()
    const minute = moment(formatDate).minute()
    const second = moment(formatDate).second()
    return {
      year,
      month,
      day,
      hour,
      minute,
      second
    }
  }

  // check if the meta
  const checkIfEmpty = (obj: any) =>
    obj === null || obj === undefined || Object.keys(obj).length === 0
      ? { error: 'no meta data' }
      : obj

  // ------------------------------

  // Extract the event data from the media.json file and create a media obj & handle null values
  // const parseObj = JSON.parse(JSON.stringify(mediaData,(_key, value) => (value === null || value === undefined || value.length === 0) ? {'error': 'no metadata'} : value))
  const parseObj = JSON.parse(JSON.stringify(mediaData))
  // Original Start Time
  const originalStartTime = moment
    .utc(ticksToDate(parseInt(parseObj.startTime)))
    .format('LLLL')
  const originalEndTime = moment
    .utc(ticksToDate(parseInt(parseObj.endTime)))
    .format('LLLL')
  // console.log(parseObj)

  // Create event object
  const createEventObj = parseObj.events.map((mediaObj: any, i: number) => {
    // 1: Get the start time
    const key = { i }
    const startTime = ticksToDate(parseInt(mediaObj.startTime))
    // 2: Get the end time and add duration to it
    const endTime = ticksToDate(parseInt(mediaObj.startTime))

    const milliseconds = parseInt(mediaObj.duration)
    const durationCalc = moment(endTime).add(milliseconds, 'milliseconds')
    const seconds =
      moment.duration(durationCalc.diff(endTime)).asSeconds() + 's'

    //3 : Get the event type, id, metadata
    const eventType = mediaObj.type
    const eventId = mediaObj.eventId

    // 4: Get the metadata if it exists, if null, undefined, or empty, return a property with an error message
    const metadata = checkIfEmpty(mediaObj.metadata)
    // console.log(metadata)
    //create the metadata object
    // format metadata object
    const formattedMetadata = (obj: any) => {
      if (obj.hasOwnProperty('error')) {
        const toolTipMetaError = `No metadata available for this event`
        return toolTipMetaError
      } else {
        const { classification, confidence } = obj
        const toolTipMeta = `Confidence: ${classification} | Classification: ${confidence}`

        return toolTipMeta
      }
    }

    // 5: Create The Tooltip Object
    const createToolTip = (obj: any) => {
      const startOfEvent = moment.utc(startTime).format('LL HH:mm:ss')
      const endOfEvent = moment.utc(durationCalc).format('LL HH:mm:ss')

      return (
        '<div style="padding:5px;">' +
        '<table style="width:350px;height:auto">' +
        '<tr>' +
        '<td>' +
        '<b>Event ID</b>: ' +
        '</td>' +
        '<td>' +
        eventId +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<b>Start Time</b>: ' +
        '</td>' +
        '<td>' +
        startOfEvent +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<b>End Time</b>: ' +
        '</td>' +
        '<td>' +
        endOfEvent +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<b>Duration</b>: ' +
        '</td>' +
        '<td>' +
        seconds +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<b>Event Type</b>: ' +
        '</td>' +
        '<td>' +
        eventType +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' +
        '<b>Metadata</b>: ' +
        '</td>' +
        '<td>' +
        formattedMetadata(obj) +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>'
      )
    }

    // console.log(formattedMetadata(metadata), 'formatted metadata')

    // 5: create the event object
    return {
      key,
      startTime: formatTime(startTime),
      endTime: formatTime(durationCalc),
      eventType,
      eventId,
      seconds,
      metadata: createToolTip(metadata)
    }
  })

  // ------------------------------

  //1 - Create the columns for the timeline
  //2 - Create the key for the timeline
  //3 - Create the rows for the timeline

  const columns = [
    { type: 'string', label: 'Events' },
    { type: 'string', label: 'row label' }, // this is needed to enable the custom tooltip
    { type: 'string', role: 'tooltip' },
    { type: 'date', label: 'startDate' },
    { type: 'date', label: 'duration' }
  ]

  const key = createEventObj.map((obj: any) => obj.key.i)

  const rows = createEventObj.map((event: any) => {
    const { startTime, endTime, eventType, eventId, metadata, seconds } = event

    return [
      eventType,
      seconds, // this will be the row label
      metadata, // this will be the tooltip text
      new Date(
        startTime.year,
        startTime.month,
        startTime.day,
        startTime.hour,
        startTime.minute,
        startTime.second
      ),
      new Date(
        endTime.year,
        endTime.month,
        endTime.day,
        endTime.hour,
        endTime.minute,
        endTime.second
      )
    ]
  })

  const options = {
    enableInteractivity: true,
    hAxis: {
      format: 'HH:mm:ss'
    },
    backgroundColor: '#f5f5f5',
    allowHtml: true,
    timeline: {
      showRowLabels: false,
      barLabelStyle: {
        fontSize: 14,
        color: '#fff'
      }
    }
  }

  return (
    <div data-testid='timeline'>
      <article className='prose'>
        <h1>
          Timeline for <em>{mediaData.name}</em>
        </h1>
        <p>This is a timeline of the events that occurred during the video.</p>
        <p>Start Time: {originalStartTime}</p>
      </article>

      <Chart
        graph_id='event_timeline'
        key={key}
        width='1080px'
        height='300px'
        chartType='Timeline'
        options={options}
        loader={<div>Loading Chart</div>}
        data={[columns, ...rows]}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  )
}
