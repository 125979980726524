import React, {Fragment} from 'react'
import TimelineComponent from '../Components/TimeLine/TimelineComponent'

type Props = {}

const Home = (props: Props) => {
  return (
    <Fragment>
      <TimelineComponent />
    </Fragment>
    
  )
}

export default Home