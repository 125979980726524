import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './Components/layout/Navbar'
import Footer from './Components/layout/Footer'
import Home from './pages/Home'
import Error from './pages/Error'
import About from './pages/About'

function App () {
  return (
    <Router>
      <div data-theme='cupcake' className='flex flex-col justify-between h-screen'>
        <Navbar />
        <main className='mx-auto px-3 pb-12'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/*' element={<Error />} />
        </Routes>
        </main>
        <Footer />
      
      </div>
    </Router>
  )
}

export default App
