import React from 'react'


type Props = {}
const footerYear = new Date().getFullYear()

const Footer = (props: Props) => {
  return (
    <footer className='footer p-10 bg-primary footer-center'>
      <div>
       
        <p>Copyright &copy; {footerYear} Patrick Tangney</p>
      </div>
    </footer>
  )
}

export default Footer