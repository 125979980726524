import React, { Fragment } from 'react'
import { VscSmiley } from 'react-icons/vsc'

type Props = {}

const About = (props: Props) => {
  return (
    <Fragment>
      <h1 className='text-6xl mb-4'>Kinesense Coding Assignment</h1>
      <p className='mb-4 text-2xl font-light'>
        A React app to display a collection of events that are the result of a
        video analysis.
      </p>

      <p className='text-lg text-gray-400 mb-10'>
        Version <span className='text-primary'>1.0.0</span>
      </p>
      <p className='text-lg'>
        Github Repo:{' '}
        <a
          className="text-purple-700 after:content-['_↗'] ..."
          href='https://github.com/patangney/kinsense-ap'
        >
          @patangney
        </a>
      </p>

      <h1 className='mt-2 text-2xl mb-4'>Tech Used</h1>

      <div className='mockup-code'>
        <pre data-prefix='1'>
          <code>Tailwindcss Daisyui React-icons (UI)</code>
        </pre>
        <pre data-prefix='2'>
          <code>React-Google-Graphs</code>
        </pre>
        <pre data-prefix='3'>
          <code>
            <progress className='progress progress-warning w-56'></progress>
          </code>
        </pre>
        <pre data-prefix='4' className='bg-success text-warning-content'>
          <code>
            And lots of other goodies{' '}
            <VscSmiley className='inline pr-2 text-3xl' />
          </code>
        </pre>
      </div>
    </Fragment>
  )
}

export default About
